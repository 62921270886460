#map-container {
    position: relative;
}

#map-container .bg {
    width: 100%;
}

.loc-container, .loc, .icon-active {
    position: absolute;
}

.loc.hover, .loc-container:hover .loc.normal {
    visibility: hidden;
}

.loc-container:hover .loc.hover {
    visibility: visible;
}

.loc-container:hover {
    cursor: pointer;
}

.loc-container .disabled:hover {
    cursor: initial;
}

.icon-active {
    transform: translate(20%, 10%);
    top:0;
    left: 50%;
    width:100%;
    height: 100%;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateX(0);
    }
    40% {
        transform: translateX(-1%);
    }
    60% {
        transform: translateX(-0.5%);
    }
}

.bounce {
    animation-name: bounce;
    animation-duration: 1.5s;
	animation-fill-mode: both;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}