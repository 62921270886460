.pdf-container {
  position: relative;
  background: white;
  border: 1px solid #000;
  height: auto;
  margin: 0 auto;
  box-sizing: content-box;
  overflow: auto;
  min-width: 250px;
  min-height: 80px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}
.pdf-controls {
  position: absolute;
  top: 0px; /* Adjust the desired spacing from the bottom */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  z-index: 99;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(3px);
  border: 1px solid #000;
  border-top: 0px solid;
  padding: 2px;
}

.control-button:disabled {
  color: gray;
  cursor: disabled;
}
.control-button {
  margin: 0 10px;
}

.pdf-container.fullscreen .pdf-page-container {
  width: 100%;
  height: 100%;
}
.pdf-container.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  z-index: 9999;
}

.pdf-loading,
.pdf-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
}
